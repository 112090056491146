<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <ag-grid-table ref="tablaHistoricoCarga" :configUrl="historicoCargaConfig" :dataUrl="historicoCargaData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
BRow,
BCol,
BCard,
BButton,
BCardHeader,
BFormFile,
BCardTitle,
BCardSubTitle,
BCardText,
BCardBody,
BFormSelect,
BIconNodeMinusFill,
BTabs,
BSpinner,
BAlert,
BTab,
BLink,
VBPopover,
VBTooltip,
BFormGroup,
BFormInvalidFeedback

} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useApiServices from '@/services/useApiServices.js';

export default {
  data() {
    return {
      historicoCargaConfig: useApiServices.historicoCargaConfig,
      historicoCargaData: useApiServices.historicoCargaData,
      itemsData: [],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },
  methods: {
    refreshInfo() {
      this.updateCards();
      this.$refs.tablaHistoricoCarga.refreshRows();
    },

    updateCards() {
      axios.get(useApiServices.historicoCargaStatistics)
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    }
  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

