<template>
  <b-card>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title></b-card-title>
        <b-card-sub-title></b-card-sub-title>
      </div>
    </div>
    <b-card-body>

      <b-card-text>
            <b-card-title>
             Credenciales SAP
            </b-card-title>

            <b-row class="match-height">
              <b-col md="8">
                <BrunaForm  :fields="fields" :card="card">
                  <template v-slot:footer>
                    <b-button variant="primary" class="mt-4 float-right" @click="sync()">Sincronizar</b-button>
                  </template>
                </BrunaForm>
              </b-col>
            </b-row>
          </b-card-text>

    </b-card-body>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import axios from '@axios'

export default {
  name: "sincronizar",
  data() {
    return {
      card: {
          title: "",
          subtitle: "",
          tooltip: "",
      },

      fields: [
        {
          label: "SAP USER",
          type: "input",
          id: 'user',
          rules: 'required',
          format: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
          },
          value: 'sb-2d987d75-0e9e-4e22-8135-e5b3d749afe9!b125809|farming!b120273'
        },
        {
          label: "SAP SECRET",
          type: "input",
          id: 'password',
          rules: 'required',
          format: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
          },
          value: '*******************************'
        }
      ],
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BrunaForm,
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },

  methods: {

    sync(){
      const options = {
        method: 'GET',
        url: 'https://sapio-c175-d.authentication.eu10.hana.ondemand.com/oauth/token/',
        params: {grant_type: 'client_credentials'},
        auth: {
          username: 'sb-2d987d75-0e9e-4e22-8135-e5b3d749afe9!b125809|farming!b120273',
          password: 'FiuPBcI2FSPiXjWvRXRtf3UE7Zs='
        },
      };
      axios.request(options)
      .then((response) => {
          // console.log(response);
          document.cookie = `token=${response.data.access_token};  SameSite=None;`
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Sincronizacion exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `Se han sincronizado los registros exitosamente.`,
            },
          });

          axios.post(useApiServices.syncCrops, {token: response.data.access_token}).then((response) => {console.log(response)});
        })
      .catch((error) => {
          let message = error;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: message,
            },
          });

        });
      }
    }


};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

