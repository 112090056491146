<template>
  <section id="dashboard-sap-sync">
    <b-tabs v-model="tabIndex">
      <!-- Tab Carga de Archivos -->
      <b-tab title="Sincronizacion SAP intelligence agriculture">
        <sap-sync-tab-vue @upload="updateHistorico"></sap-sync-tab-vue>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import SapSyncTabVue from "./SapSyncTab.vue";
import HistoricoSyncTabVue from "./HistoricoSyncTab.vue";

export default {
  data() {

    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    if (activeTabName == "Carga de Archivos") {
      tabIndex = 0
    }

    if (activeTabName == "Histórico de Carga") {
      tabIndex = 1
    }

    return {
      tabIndex: tabIndex
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,

    SapSyncTabVue,
    HistoricoSyncTabVue
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },

  methods: {
    updateHistorico() {
      this.$refs.historicoCarga.refreshInfo()
    }
  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

